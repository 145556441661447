.diamond_price_container {
  width: 60%;
  display: flex;
  /* min-height: 80vh; */
  margin-inline: auto;
}

.logo_garena {
  margin-top: 3rem;
  margin-bottom: -2rem;
  width: 24rem;
  margin-inline: auto;
}

.diamond_info {
  margin-inline: auto;
  display: flex;
  flex-wrap: wrap;
  width: 90%;
}

.diamond_info > p {
  color: white;
  min-width: 50%;
  max-width: 50%;
  /* padding: 0.5rem;
  font-size: 2rem; */
  text-align: center;
  font-family: kalam;
  font-size: 1.8rem;
  font-weight: bold;
  overflow-wrap: break-word;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(53, 50, 50);
}

.diamond_info > p > span#blue_color {
  color: #65ecec;
  background-color: transparent;
  margin: 0;
  padding: 0;
}

.diamond_info :nth-child(1) {
  /* padding: 1rem; */
  text-align: center;
  background-color: #333535;
  color: red;
}
.diamond_info :nth-child(2) {
  text-align: center;
  background-color: #333535;
  color: red;
}
.diamond_info :nth-child(3) {
  text-align: center;
}

@media screen and (max-width: 400px) {
  .logo_garena {
    width: 13rem;
  }

  .diamond_info > p {
    font-size: 1rem;
  }
  .permanent_marker{
    font-size: 1rem;
  }


}

@media screen and (max-width: 600px) {
  .diamond_info {
    width: 90%;
  }
  .diamond_price_container {
    width: 95%;
  }
}

