

.logo_direction{
    width: 40%;
    display: flex;
    margin-inline: auto;
    margin-bottom: 3rem;
}

@media screen and (max-width: 400px){
   .logo_whats{
    width: 1rem;
   }
}

@media screen and (max-width: 600px){
    .logo_direction{
        width: 30rem;
        margin-bottom: 0;
    }
}