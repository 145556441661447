
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: rgb(0, 0, 0);
  width: 100%;
}

.app {
  position: relative;
  z-index: 1;
}

.section {
  width: 100vw;
  height: fit-content;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}


