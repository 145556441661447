
.rombo_principal_container{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-inline: auto;
    
}

.rombo_container{
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
}


.rombo2 {
    width: fit-content;
    height: fit-content;
    line-height: 1;
    color: white;
    font-size: 3rem;
    display: flex;
    align-items: center;
    
    font-family: permanenteMarker;
  } 
  .rocket{
    color: white;
    transform: rotate(-225deg);
    font-size: 4rem;
    margin-right: 20px;
    color: #abf8f8;
  }



  @media screen and (max-width:600px){
    .rombo_principal_container{
        width: 90%;
    }

    .rombo_container{
      margin-bottom: 0;
    }

   .rombo2{
    font-size: 1.5rem;
    width: 100%;
   }
  }

  @media screen and (max-width:400px){
    .rombo_principal_container{
        width: 90%;
    }
   .rombo2{
    font-size: 1rem;
    width: 100%;
   }

   .logo_direction{
    width: 17rem;
   }

  }


  


 
  