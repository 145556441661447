.to_home {
  z-index: 4;
  position: fixed;
  color: #abf8f8;
  font-size: 3rem;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2%;
  display: flex;
  gap: 2rem;
  border-radius: 3rem;
  backdrop-filter: blur(5px);
}


.active {
  color: var(--color-white);
}
