.title_cologne_container{
  position: relative;
  bottom: 7rem;
  z-index: 5;
}

.title_cologne{
    color: white;
    width: 60%;
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline: auto;
  }
  

  .whatsapp_icon{
    color: rgb(255, 255, 255);
    font-size: 7rem;
    opacity: .7;
  }

  @media screen and (max-width: 600px) {

    .title_cologne_container{
      top: 3rem;
    }

    .whatsapp_icon{
      font-size: 9.5rem;
    }
  }

  @media screen and (max-width: 400px) {

    .title_cologne_container{
      top: 3rem;
    }

    .whatsapp_icon{
      font-size: 3.5rem;
    }
  }