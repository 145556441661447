.pay_here{
  color: white;
  width: 100%;
  font-family: permanenteMarker;
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 1rem;
  font-size: 2rem;

}
.services_container {
  width: 50%;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.logo_megacable {
  width: 32rem;
  background-color: aliceblue;
  margin-bottom: 3rem;
  border-radius: 8px;
}

.logo_cfe {
  width: 20rem;
  margin-bottom: 3rem;
}

.logo_bait {
  width: 24rem;
  display: flex;
  margin-inline: auto;
}

.reload {
  color: white;
  font-family: permanenteMarker;
  position: absolute;
  font-size: 1.9rem;
  right: 50%;
}
.other_companies{
  display: flex;
  justify-content: center;
  margin-top: 0.8rem;
}

.companies{
 height: 4rem;
}

.border_left{
  border-radius: 10px 0 0 10px;
}

.border_right{
  border-radius: 0px 10px 10px 0;
}


@media screen and (max-width: 600px){
    .logo_megacable{
      width: 18rem;
      height: 8rem;
      margin-bottom: 4rem;
      margin-top: 3rem;
    }
    .logo_cfe{
      width: 18rem;
    }
    .logo_bait{
      width: 18rem;
    }
    h1{
      width: 24rem;
    }
    .reload {
      right: 49%;
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 400px) {
    .pay_here{
      font-size: 1.3rem;
      margin-top: 0.5rem;
      margin-bottom: 0;
    }
    .logo_megacable{
      width: 13rem;
      height: 6rem;
      margin-bottom: 2rem;
    }

    .logo_cfe {
      width: 13rem;
      margin-bottom: 1rem;
    }

    .logo_bait{
      width: 13rem;
    }

    .reload {
      font-size: 1rem;
      right: 50%;
    }

    .other_companies{
      right: 1%;
      top: 103%;
    }

    .companies{
      width: 3rem;
      height: 2.5rem;
      
    }

  }