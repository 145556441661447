*, *::after, *::before {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
  }

  @font-face {
    font-family: permanenteMarker;
    src: url(./components/logo_arkal_react_icon/PermanentMarker-Regular.woff2) format("woff2");
    unicode-range: U+0000-00ff;
  }

  @font-face {
    font-family: kalam;
    src: url(./components/logo_arkal_react_icon/Kalam-Regular.ttf);
  }

  a:-webkit-any-link{
    color: #abf8f8;
  }

html{
	scroll-behavior: smooth;
}

::-webkit-scrollbar{
	display: none;
}

:root{
  --bg-lg: 90%;
  --bg-md: 80%;
}

.permanent_marker {
  color: white;
  width: 100%;
  font-family: permanenteMarker;
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 1rem;
  font-size: 2rem;
}







